<template>
    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-5 qty_wrapper"> -->
        <div class="add-quantity">
            <span class="minus-quant" @click="substractQty">
                <!-- <img v-if="isMobile" src="@/assets/images/qty_minus.svg" /> -->
                <font-awesome-icon icon="minus" class="fa-solid" />
            </span>
            <input
                tpe="text"
                name="quantity-prod"
                v-bind="quantity"
                :value="quantity"
                maxlength="3"
                class="quantity-input only_float_num"
                v-on:keyup="inputPress"
            />
            <input type="hidden" name="update" value="1" />
            <span class="plus-quant" @click="addQty"
                ><font-awesome-icon icon="plus" class="fa-solid"
            /></span>
        </div>
    <!-- </div> -->
</template>

<script>
export default {
    name: "ChangeQuantity",
    data() {
        return {
            quantityLimit: 120,
        };
    },
    props: {
        quantity: Number,
        value: Number,
    },
    mounted() {},
    computed: {
        qtyInput() {
            return document.querySelector(".quantity-input");
        },
        isMobile() {
            return window.outerWidth < 768 ? true : false;
        },
        // quantity() {
        //     return this.$store.getters["shop/quantity"];
        // },
    },
    methods: {
        addQty() {
            if (this.qtyInput.value < this.quantityLimit) {
                this.qtyInput.value++;
            }
            this.$store.dispatch("shop/changeQty", this.qtyInput.value);
        },

        substractQty() {
            if (this.qtyInput.value > 1) {
                this.qtyInput.value--;
            }
            this.$store.dispatch("shop/changeQty", this.qtyInput.value);
        },

        inputPress() {
            if (this.qtyInput.value < 0) {
                this.qtyInput.value = 1;
            } else if (this.qtyInput.value > this.quantityLimit) {
                this.qtyInput.value = this.quantityLimit;
            }
        },
    },
};
</script>

<style scoped>
.add-quantity {
    display: inline-flex;
}

.add-quantity .quantity-input {
    font-family: "Montserrat Bold";
    font-size: 16px;
}

span.plus-quant, span.minus-quant {
  background-color: #fff;
  color: #a9a4a4;
  width: 40px;
  font-size: 12px;
}

span.plus-quant {
  border-radius: 0 100px 100px 0;
}

span.minus-quant {
  border-radius: 100px 0 0 100px;
}

@media (max-width: 768px) {
    .minus-quant,
    .plus-quant {
        background-color: #fff;
    }

    .fa-minus,
    .fa-plus {
        color: #000;
    }

    .quantity-input {
        font-size: 15px;
    }

    span.minus-quant,
    span.plus-quant {
        font-size: 12px;
    }

    .add-quantity {
        float: left;
        width: 35%;
    }
}
</style>
