<template>
    <section class="container p-3 rounded product-page-detailed pb-4 outlet">
        <div
            v-if="product.name"
            class="row"
            itemscope
            itemtype="http://schema.org/Product"
        >
            <div class="col-lg-6 product-image">
                <brief-info
                    :name="product.name"
                    :code="product.code"
                    :brand="product.brand"
                />
                <div class="py-2"></div>
                <carousel
                    v-if="windowWidth > 768"
                    :images="product.images"
                    :product="product"
                />
                <product-carousel-mobile
                    v-if="windowWidth < 769"
                    :images="product.images"
                    :product="product"
                />
            </div>
            <div class="col-lg-6 product-info">
                <template
                    v-if="
                        product.offers.length &&
                        product.offers[0].price_value > 0 &&
                        product.active
                    "
                >
                    <product-price :offer="product.offers[0]" />

                    <ProductStock
                        :offer="product.offers[0]"
                        :soldout="product.sold_out"
                        :outlet="product.has_related_parent"
                    />

                    <shipping-date
                        v-if="product.available_from"
                        :date="product.available_from"
                    />

                    <action-buttons
                        v-if="product.offers[0].quantity && product.sold_out !== 1"
                        :offer="product.offers[0]"
                    />
                    <div v-else class="py-3">
                        <pf-button
                            :style="{ marginTop: '10px' }"
                            :class="'btn btn-border-radius btn-red'"
                            :href="'/produs/' + product.related_parent_slug"
                        >
                            Vezi produs nou
                        </pf-button>
                    </div>
                </template>
                <template v-else-if="!product.active">
                    <inactive-product-message :categ="product.fist_categ" />
                </template>
                <template v-else-if="product.sold_out === 1">
                    <div class="alert alert-danger col-md-12">
                        <strong>Stoc epuizat</strong>
                        <meta itemprop="price" content="<?php echo $produs->pret; ?>" />
                        <meta itemprop="priceCurrency" content="LEI" />
                    </div>
                </template>
                <template v-else-if="product.offers[0].price_value === 0">
                    <ProductStock
                        :offer="product.offers[0]"
                        :soldout="product.sold_out"
                    />
                    <hr style="margin-bottom: 20px" />
                    <ask-for-offer :technical="product.technical_sheet" />
                    <ask-for-offer-modal ref="askForOfferModal" />
                    <hr />
                </template>

                <div v-if="product.preview_text !== ''" class="title-desc-wrp">
                    <div class="specification-title"> Detaliu outlet </div>
                    <div class="short-description" >
                        <span v-html="product.preview_text"> </span>
                    </div>
                </div>

                <specifications :properties="properties" />
                <secondory-description :description="product.bottom_content" />

                <pf-button
                    v-if="product.technical_sheet"
                    :style="{ marginTop: '10px' }"
                    :class="'btn btn-default btn-large tech-btn btn-black '"
                    :href="product.technical_sheet"
                    target="_blank"
                >
                    Detalii Tehnice
                </pf-button>

                <form class="mb-0">
                    <input type="hidden" name="offer_id" value="{{ obOffer.id }}" />
                </form>
            </div>
            <div
                class="wysiwyg mt-3 product-content"
                :class="[product.accesories.length ? 'col-lg-6' : 'col-lg-12']"
            >
                <more-info :description="product.description" />
            </div>
            <div v-if="product.accesories.length > 0" class="col-lg-6 product-options">
                <product-options :accesories="product.accesories" />
            </div>
            <div class="col-lg-6 product-info-points">
                <info-points :slug="product.slug" :id="product.id" />
            </div>
        </div>
    </section>
</template>

<script>
import BriefInfo from "@/components/product_page/BriefInfo.vue";
import Carousel from "@/components/product_page/Carousel.vue";
import ProductCarouselMobile from "@/components/product_page/ProductCarouselMobile.vue";
import ProductPrice from "@/components/product_page/ProductPrice.vue";
import ActionButtons from "@/components/product_page/ActionButtons.vue";
import Specifications from "@/components/product_page/Specifications.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
import ProductOptions from "@/components/product_page/ProductOptions.vue";
import InfoPoints from "@/components/product_page/InfoPoints.vue";
import AskForOffer from "@/components/product_page/AskForOffer.vue";
import SecondoryDescription from "@/components/product_page/SecondoryDescription.vue";
import PfButton from "@/components/buttons/PfButton.vue";
import InactiveProductMessage from "@/components/product_page/InactiveProductMessage.vue";
import ShippingDate from "@/components/product_page/ShippingDate.vue";
import AskForOfferModal from "@/components/product_page/AskForOfferModal.vue";
import ProductStock from "@/components/product_page/ProductStock.vue";

export default {
    name: "ProductContainer",
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    components: {
        "brief-info": BriefInfo,
        Carousel,
        "product-price": ProductPrice,
        ActionButtons,
        Specifications,
        MoreInfo,
        ProductOptions,
        InfoPoints,
        AskForOffer,
        SecondoryDescription,
        PfButton,
        InactiveProductMessage,
        ProductCarouselMobile,
        ShippingDate,
        AskForOfferModal,
        ProductStock,
    },

    computed: {
        product() {
            // console.log(this.$store.getters["shop/product"].active === 0);
            return this.$store.getters["shop/product"];
        },

        properties() {
            let new_array = this.product.property.filter((elem) => {
                if(elem.code == "garantie"){ 
                      return false; 
                }
                
                return elem.code !== "layout"; 
            });

            return new_array;
        },
    },

    methods: {
        // oldprice(product) {
        //   if (product.offers.length) {
        //       if (product.offers[0].old_price_value > 0) {
        //         return product.offers[0].old_price_value;
        //     }
        //   }
        //   return false;
        // }
    },
};
</script>

<style>
@import "../../assets/css/product.css";
@import "../../assets/css/common.css";
@import "../../assets/css/list_products.css";
.specification-title {
  font-family: "Raleway Bold";
  font-size: 16px;
  font-weight: bold;
  color: #b60000;
  margin: 20px 0;
}

@media (min-width: 991px) {
  .outlet .thumb-gallery {
    bottom: 143px;
    left: -143px;
  }
}

@media (min-width: 1200px) {
  .outlet .thumb-gallery {
    bottom: 187px;
    left: -187px;
  }
}
</style>
