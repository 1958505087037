<template>
  <!-- <a
    class="carousel-control-prev"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="prev"
  > -->
  <a class="carousel-control-prev" role="button">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
</template>

<script>
export default {
  name: "CarouselPrev",
};
</script>
