<template>
  <section v-if="product.name" class="container p-3 rounded product-page-detailed pb-4 custom-door">
    <brief-info :name="product.name" :code="product.code" :text="product.preview_text" />

    <div class="calculator-sticla-wrapper calc-usa calculator-pret" id="calc-usa">
      <div class="row">
        <div class="calculator-sticla col-12 col-md-7">
          <div class="options custom-fields">
            <custom-door-dimensions />
            <custom-door-image-card
              :id="'img-animate'"
              :classes="'d-block d-md-none'"
              :image="images.sketchImage"
            >
              <div id="draw-inaltime" class="text_is draw-inaltime">Înălțime</div>
              <div id="draw-lungime" class="text_ls draw-lungime">Lățime</div>
            </custom-door-image-card>
          </div>

          <div class="options custom-fields">
            <custom-door-side />
          </div>

          <div class="options custom-fields">
            <custom-door-type />
            <custom-door-image-card
              :id="'img-animate-2'"
              :classes="'d-block d-sm-none'"
              :image="images.threeDImage"
            >
            </custom-door-image-card>
          </div>

          <div class="options custom-fields">
            <div class="agreements row">
              <div class="col-12">
                <label class="title">4. Acord comandă specială</label>
              </div>
              <div class="col-12">
                <div class="no-retur">
                  <label for="no-retur">
                    <input
                      type="checkbox"
                      name="no-retur"
                      value="left"
                      id="no-retur"
                      required
                      @click="noRetur"
                    />
                    <span class="label-text calc-options-label"
                      >Am luat la cunoștință că produsul nu se poate returna</span
                    >
                  </label>
                </div>
              </div>

              <div class="col-12">
                <div class="payment-before">
                  <label for="payment-before">
                    <input
                      type="checkbox"
                      name="payment-before"
                      value="left"
                      id="payment-before"
                      required
                      @click="paymentBefore"
                    />
                    <span class="label-text calc-options-label"
                      >Am luat la cunoștință că plata se face doar în avans</span
                    >
                  </label>
                </div>
              </div>
            </div>
          </div>

          <total-price-calculator
            :is-complete="allIsComplete"
            :width="doorWidthDim"
            :height="doorHeightDim"
          />

          <div class="relative order-inputs">
            <div
              id="tooltip"
              data-tip="Completați toți cei 4 pași pentru a putea comanda!"
              class="show-tooltip"
            >
              <input-button
                :disabled="!allIsComplete"
                :id="'prod-to-cart'"
                :name="'add_one_item'"
                v-on:click="addToCart()"
              />
            </div>
          </div>
        </div>

        <AddToCartModal :quantity="1" ref="addtocart" />
        <div class="col-12 col-md-5 d-md-block d-none">
          <custom-door-image-card :id="'img-animate'" :image="images.sketchImage">
            <div id="draw-inaltime-desk" class="text_is draw-inaltime">Înălțime</div>
            <div id="draw-lungime-desk" class="text_ls draw-lungime">Lățime</div>
          </custom-door-image-card>

          <custom-door-image-card :id="'img-animate-2'" :image="images.threeDImage">
          </custom-door-image-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 product-image">
        <carousel :images="product.images" :type="'custom'" />
      </div>
      <div class="col-lg-6 product-info">
        <!-- <specifications /> -->
        <instead-of-specification :description="getCustomDescription" />
      </div>

      <div class="col-12 wysiwyg mt-3 product-content">
        <more-info :description="product.description" />
      </div>

      <div class="col-12 product-info-points">
        <info-points :slug="product.slug" :id="product.id" />
      </div>
    </div>
  </section>
</template>

<script>
import CustomDoorDimensions from "@/components/product_page/custom_door/CustomDoorDimensions.vue";
import TotalPriceCalculator from "@/components/product_page/custom_glass/TotalPriceCalculator.vue";
import CustomDoorImageCard from "@/components/product_page/custom_door/CustomDoorImageCard.vue";
import CustomDoorSide from "@/components/product_page/custom_door/CustomDoorSide.vue";
import CustomDoorType from "@/components/product_page/custom_door/CustomDoorType.vue";
import BriefInfo from "@/components/product_page/BriefInfo.vue";
import Carousel from "@/components/product_page/Carousel.vue";
import InsteadOfSpecification from "@/components/product_page/InsteadOfSpecification.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
import InfoPoints from "@/components/product_page/InfoPoints.vue";
import InputButton from "@/components/buttons/InputButton.vue";
import AddToCartModal from "@/components/product_page/AddToCartModal.vue";

export default {
  components: {
    CustomDoorDimensions,
    TotalPriceCalculator,
    CustomDoorSide,
    CustomDoorType,
    CustomDoorImageCard,
    BriefInfo,
    Carousel,
    InsteadOfSpecification,
    MoreInfo,
    InfoPoints,
    InputButton,
    AddToCartModal,
  },

  data() {
    return {
      isNotReturned: false,
      isAdvancedPay: false,
      doorPos: { left: "Stanga", right: "Dreapta" },
      doorType: { inner: "Interior", outer: "Exterior" },
    };
  },

  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
    allIsComplete() {
      return (
        this.doorHeightDim > 0 &&
        this.doorWidthDim > 0 &&
        this.isPositionSelected &&
        this.isTypeSelected &&
        this.isAdvancedPay &&
        this.isNotReturned
      );
    },

    doorWidthDim() {
      return this.$store.getters["door/doorWidthDim"];
    },

    doorHeightDim() {
      return this.$store.getters["door/doorHeightDim"];
    },

    isPositionSelected() {
      return this.$store.getters["door/isPositionSelected"];
    },

    isTypeSelected() {
      return this.$store.getters["door/isPositionSelected"];
    },

    price() {
      return this.$store.getters["door/doorPrice"];
    },
    productName() {
      var name = this.product.name;
      name += " / " + this.doorPos[this.$store.getters["door/doorPosition"]];
      name += " / " + this.doorType[this.$store.getters["door/doorType"]];
      name += " / inaltime:" + this.doorHeightDim + " mm";
      name += " / lungime:" + this.doorWidthDim + " mm";

      return name;
    },

    images() {
      return this.$store.getters["door/images"];
    },
    getCustomDescription() {
      let product = this.product;
      let description = {};
      if (product.layout_property) {
        description = product.layout_property.find((item) => {
          return item.code === "product-short-description";
        });
      }
      return description?.value ? description.value : "";
    },
  },

  methods: {
    noRetur() {
      this.isNotReturned = !this.isNotReturned;
    },

    paymentBefore() {
      this.isAdvancedPay = !this.isAdvancedPay;
    },
    addToCart() {
      // console.log("adaugam in cos usa");
      this.$store
        .dispatch("shop/addToCart", {
          offer_id: this.product.offers[0].id,
          quantity: 1,
          property: {
            "custom-name": this.productName,
            "custom-price": this.price,
            "custom-options": JSON.stringify({
              latime: this.doorWidthDim,
              inaltime: this.doorHeightDim,
              pozitie: this.$store.getters["door/doorPosition"],
              tip: this.$store.getters["door/doorType"],
            }),
          },
        })
        .then(() => {
          this.$store.dispatch("modals/addToCartModal", true);
          this.$store.dispatch("door/actionDoorHeightDim", 0);
          this.$store.dispatch("door/actionDoorWidthDim", 0);
          this.$store.dispatch("door/setPrice", 0);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/customDoor.scss";
</style>
