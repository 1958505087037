<template>
  <div class="row" id="actions">
    <div v-if="technical" class="col-12 col-sm-6 btn-holder">
      <pf-button
        :class="'btn btn-default btn-large tech-btn btn-black btn-border-radius'"
        :href="technical"
        target="_blank"
      >
        Detalii Tehnice
      </pf-button>
    </div>
    <div class="col-12 col-sm-6 btn-holder">
      <pf-button
        :class="'btn btn-red btn-large comanda-rapida btn-border-radius'"
        @click="showModal"
      >
        Cere Oferta
      </pf-button>
    </div>
    <div class="col-12 offer-message">
      În maxim <span>24 de ore lucrătoare</span> te vom contacta și îți vom răspunde.  Nu ezita, e simplu și rapid.
    </div>
  </div>
</template>

<script>
import PfButton from "@/components/buttons/PfButton.vue";

export default {
  props: ["technical"],

  components: {
    PfButton,
  },

  methods: {
    showModal() {
      this.$store.dispatch("modals/askForOfferState", true);
    },
  },
};
</script>
<style scoped>
.offer-message {
  font-family: Raleway Medium;
  font-size: 12px;
  color: #2e2e30;
}

.offer-message span {
  font-family: Raleway Bold;
}
</style>
