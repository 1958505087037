<template>
  <div class="produs-content">
    <read-more
      v-if="isMiddleDevice && shortDesc"
      more-str="Citeste mai mult..."
      :text="description"
      link="#"
      less-str="Citeste mai putin"
      :max-chars="150"
    ></read-more>
    <div v-else v-html="description"></div>
  </div>
</template>

<script>
import fitvids from "fitvids";

export default {
  name: "MoreInfo",
  props: {
    description: {
      type: String,
    },
    shortDesc: {
      type: Boolean,
      defaul: false,
    },
  },
  computed: {
    isMiddleDevice() {
      return this.$mq === "sm" ? true : false;
    },
  },
  mounted() {
    var b = document.querySelectorAll("iframe");
    if (b) {
      b.forEach(function (element) {
        element.setAttribute("allowFullScreen", "1");
      });
    }
    fitvids();
  },
};
</script>

<style lang="scss">
.produs-content img {
  max-width: 100%;
  max-height: none;
  height: auto !important;
}

#readmore {
  color: #900;
}

</style>

<style scoped>
@media (max-width: 768px) {
  .produs-content >>> .columns.medium-6.small-12 {
    width: 100% !important;
  }

  .produs-content >>> .specs-table {
    width: 100% !important;
  }
}
</style>
