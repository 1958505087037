<template>
  <div v-if="boughtWithProducts.length > 0" class="bought-with-products">
    <div class="boughtwith-title">
      Cumpărate frecvent împreună
    </div>
    <div id="boughtWithProductsNav" class="boughtWithProductsNav">
      <swiper :breakpoints='navBreakpoints' :navigation='navNavigation'>
          <swiper-slide v-for="(category, index) in categoriesNav" :key="index">
            <div class="categ-wrapper" @click="goToSlide(category.navIndex)" :class="{'buttonActive': (category.navIndex==currentIndex)}">
              {{ category.categName }}
            </div>
          </swiper-slide>
      </swiper>
      <div class="swiper-nav">
        <div class="swiper-button-next-custom"></div>
        <div class="swiper-button-prev-custom"></div>
      </div>
    </div>
    <div id="boughtWithProductsCarousel" class="boughtWithProductsCarousel">
      <swiper :breakpoints='breakpoints' :scrollbar='{ draggable: true }' :navigation='navigation' :rewind='true'>
        <swiper-slide v-for="product in boughtWithProducts" :key="product.id">
          <product-card :product="product" :key="product.id" />
          <div
            class="quick-add-to-cart"
            v-on:click="addToCart(product.offers[0], product)"
            :class="{ blockBtn: sending[product.offers[0].id] || product.sold_out }"
          >
            <a>
              <img src="~@/assets/images/quick-add-to-cart.svg">
            </a>
            <loading
              :active="sending[product.offers[0].id]"
              :is-full-page="false"
              :height="25"
              :width="25"
              :opacity="0.5"
            ></loading>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-nav">
        <div class="swiper-button-next-custom"></div>
        <div class="swiper-button-prev-custom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/product/ProductCard.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

export default {
  components: {
    ProductCard,
    Loading,
    Swiper, 
    SwiperSlide,
  },
  name: "BoughtWithProducts",
  data() {
    return {
      boughtWithProducts: [],
      sending: [],
      categoriesNav: [],
      currentIndex: '',
      navBreakpoints: {
        100: {
          slidesPerView: 2.2,
          spaceBetween: 5,
          slidesPerGroup: 2,
        },
        576: {
          slidesPerView: 3.5,
          spaceBetween: 10,
          slidesPerGroup: 3,
        },
        768: {
          slidesPerView: 5.5,
          spaceBetween: 15,
          slidesPerGroup: 5,
        },
      },
      navNavigation: {
        prevEl: '#boughtWithProductsNav .swiper-button-prev-custom',
        nextEl: '#boughtWithProductsNav .swiper-button-next-custom',
      },
      breakpoints: {
        100: {
          slidesPerView: 2.2,
          spaceBetween: 8,
          slidesPerGroup: 2,
        },
        576: {
          slidesPerView: 3.5,
          spaceBetween: 10,
          slidesPerGroup: 3,
        },
        768: {
          slidesPerView: 4.5,
          spaceBetween: 15,
          slidesPerGroup: 1,
        },
      },
      navigation: {
        prevEl: '#boughtWithProductsCarousel .swiper-button-prev-custom',
        nextEl: '#boughtWithProductsCarousel .swiper-button-next-custom',
      }
    };
  },
  props: {
    products: {
      type: Array,
    },
  },
  mounted() {
    const boughtWithArray = JSON.parse(JSON.stringify(this.products));

    // First sort the products by the category, so we can save the index of the first occurence of the category later
    boughtWithArray.sort((a, b) => {
      return a.category_id - b.category_id;
    });

    let tempCategNav = [];
    let loop_index = 0;
    // Iterate over each product and delete the properties we don't want to see on the card
    boughtWithArray.forEach((product, index) => {
      // Delete unwanted details so they don't show up on the cards
      delete product.preview_text;
      delete product.labels;
      delete product.property;

      // Make the categories array for the carousel's navbar
      if(tempCategNav[product.category_id] === undefined) {
        tempCategNav[product.category_id] = index;
        this.categoriesNav[loop_index] = {
          navIndex: index,
          categName: product.category_name,
        };
        loop_index++;
      }
    });

    this.boughtWithProducts = boughtWithArray;
  },
  methods: {
    addToCart(offer, product) {
      this.sending[offer.id] = true;
      this.$store
        .dispatch("shop/addToCart", {
          offer_id: offer.id,
          quantity: 1,
        })
        .then(() => {
            this.$store.dispatch("modals/addToCartModal", product);
            this.sending[offer.id] = false;
        });
    },
    goToSlide(slideIndex) {
      const swiper = document.querySelector('#boughtWithProductsCarousel .swiper-container').swiper;
      swiper.slideTo(slideIndex);
      this.currentIndex = slideIndex;
    },
  },
};
</script>

<style>
.bought-with-products {
  margin-top: 50px; 
  margin-bottom: 30px;
}

.bought-with-products .boughtwith-title {
  font-family: 'Raleway Bold';
  font-size: 24px;
  color: #000;
  text-align: left;
  margin-bottom: 15px;
}

#boughtWithProductsCarousel .swiper-scrollbar {
  left: 0;
  width: 100%;
}

#boughtWithProductsCarousel .swiper-scrollbar-drag {
  background-color: #000;
}

#boughtWithProductsCarousel .swiper-button-next-custom, #boughtWithProductsCarousel .swiper-button-prev-custom {
  position: absolute; 
  background-size: 45px;
  background-position: center;
  width: 45px;
  height: 45px;
  top: 44%;
  z-index: 10;
}

#boughtWithProductsCarousel .swiper-button-next-custom {
  background-image: url("~@/assets/images/arrow-carousel-right.svg");
  right: -7px;
}

#boughtWithProductsCarousel .swiper-button-prev-custom {
  background-image: url("~@/assets/images/arrow-carousel-left.svg");  
  left: -7px;
}

.swiper-button-next-custom.swiper-button-disabled, .swiper-button-prev-custom.swiper-button-disabled {
  display: none;
}

.bought-with-products .product-entity {
  width: 100%;
}

.bought-with-products .product-entity .produse-name {
  font-size: 14px;
  line-height: 1;
  height: 50px;
}

.bought-with-products .product-entity .produse-stock span {
  font-size: 11px;
}

.bought-with-products .product-entity .produse-price {
  font-size: 16px;
  padding-top: 10px;
}


.bought-with-products .product-entity .produs-image {
  border-radius: 9px;
  border: solid 1.5px #e5e5e5;
}

.bought-with-products .product-entity .lichidare-de-stoc  {
  right: 20px;
}

.bought-with-products .product-entity .item.thumb  {
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  #boughtWithProductsCarousel .swiper-nav {
    display: none;
  }

  .bought-with-products .boughtwith-title {
    font-size: 22px;
  }
}

.blockBtn {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.quick-add-to-cart {
  position: relative;
  width: 46px;
  cursor: pointer;
  margin-bottom: 30px;
}

.bought-with-products .vld-overlay {
  border-radius: 20px;
}

#boughtWithProductsNav {
  position: relative;
  margin-bottom: 30px;
}

#boughtWithProductsNav .swiper-slide {
  width: auto !important;
}

#boughtWithProductsNav .categ-wrapper {
  background-color: #fff;
  border-radius: 20px;
  font-family: 'Montserrat SemiBold';
  font-size: 13px;
  line-height: 1;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 0 10px;
  cursor: pointer;
  margin-right: 10px;
}


#boughtWithProductsNav .swiper-button-next-custom, #boughtWithProductsNav .swiper-button-prev-custom {
  position: absolute; 
  background-size: 30px;
  background-position: center;
  width: 30px;
  height: 30px;
  top: 5%;
  z-index: 10;
}


#boughtWithProductsNav .swiper-button-next-custom {
  background-image: url("~@/assets/images/arrow-carousel-right.svg");
  right: 15px;

}

#boughtWithProductsNav .swiper-button-prev-custom {
  background-image: url("~@/assets/images/arrow-carousel-left.svg");  
  left: 15px;
}

#boughtWithProductsNav .buttonActive {
  background-color: #000;
  color: #fff;
}

@media (max-width: 768px) {
  #boughtWithProductsCarousel div.produse-name {
    font-size: 12px;
    height: 54px;
  }

  .bought-with-products .product-entity .produse-stock span {
    font-size: 10px;
  }

  .quick-add-to-cart {
    width: 37px;
  }

  .quick-add-to-cart img {
    width: 37px;
    height: 37px;
  }

  #boughtWithProductsNav .categ-wrapper {
    font-size: 11px;
  }
}

</style>

