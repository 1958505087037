<template>
  <!-- <a
    class="carousel-control-next"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="next"
  > -->

  <a class="carousel-control-next" role="button">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</template>

<script>
export default {
  name: "CarouselNext",
};
</script>

<style scoped></style>
