<template>
    <div class="comanda-wrapper ">
        <div class="alert alert-danger col-md-12">
			<strong>Produsul nu se mai afla in oferta noastra!Pentru produse similare accesati urmatorul link: 
                <router-link :to="slug">{{ categ.name }}</router-link> 
            </strong>
		</div>
	</div>
</template>

<script>
export default {
    props: ['categ'], 
    computed: {
        slug() {
            if (this.categ.slug === '') {
                return { name: 'Home'}
            }
            return { name: 'ProductList', params: { slug: this.categ.slug }}
        }

    }
}
</script>