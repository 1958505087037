<template>
    <div class="produs-content">
        <p v-html="description"></p>
    </div>
</template>

<script>
export default {
    name: 'InsteadOfSpecification',
    props: ['description'],
}
</script>
