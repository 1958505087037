<template>
  <div class="options-wrapper mt-3" v-if="Object.keys(this.accesories).length !== 0">
    <div class="options-title">Opțiuni suplimentare</div>
    <Accessory
      v-for="(accessory, index) in accesories"
      :key="index"
      :accessory="accessory"
      :show="index < 3 || showMore"
    />
    <div
      v-if="!showMore && Object.keys(this.accesories).length > 3"
      id="loadMore"
      class="more-less-option-button"
      @click="loadMore"
    >
      <b>+</b> Vezi mai multe opțiuni suplimentare
    </div>
    <div v-if="showMore" id="showLess" class="more-less-option-button" @click="showLess">
      <b>-</b> Restrange optiunile suplimentare
    </div>
  </div>
</template>

<script>
import Accessory from "@/components/product_page/Accessory.vue";

export default {
  name: "ProductOptions",

  props: ["accesories"],
  components: {
    Accessory,
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    loadMore() {
      this.showMore = true;
    },
    showLess() {
      this.showMore = false;
    },
  },
};
</script>
