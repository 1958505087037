<template>
  <div v-if="images" class="carousel hoch-carousel product-detailed__carousel">
    <Carousel :wrapAround="true" :items-to-show="1.26" class="product-carousel-mobile">
      <Slide v-for="(image, idx) in images" :key="idx">
        <div class="carousel__item bg-white h-100 position-relative" @click="show(idx)">
          <img class="d-block w-100" :src="image.path" alt="" title="" loading="lazy" />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
    <vue-easy-lightbox
      escDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <product-ribons :ribons="productLabels" :product="product" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import ProductRibons from "@/components/product_page/ProductRibons.vue";
import VueEasyLightbox from "vue-easy-lightbox";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "ProductCarouselMobile",
  data() {
    return {
      imgs: [],
      visible: false,
      index: 0, // default: 0
    };
  },
  props: ["images", "product"],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ProductRibons,
    VueEasyLightbox,
  },
  computed: {
    productLabels() {
      let labels = [];
      let ecoDesign = false;
      if(this.product?.property?.length > 0) {
        this.product?.property.forEach((elem) => {
          if(elem.value == "Eco Design") {
            ecoDesign = true;
          }
        });
      }

      if(this.product?.category_labels?.length > 0) {
        this.product.category_labels.forEach((element) => {
          if(element.code == "pro") {
            labels.push(element);
          }
        });
      }

      // Push all product labels to the array
      if(this.product?.labels?.length > 0) {
        this.product.labels.forEach((element) => {
          if(!ecoDesign && element.code == "eco-design") return;
          labels.push(element);
        });
      }
      
      return labels;
    },
  },
  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    fancyImg() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered

        for (var i = 0; i < this.images.length; i++) {
          this.imgs.push(this.images[i].path);
        }
      });
    },
  },
  //   mounted() {
  //       this.fancyImg();
  //   },
  //   updated() {
  //       this.fancyImg();
  //   }
  created() {
    for (var i = 0; i < this.images.length; i++) {
      this.imgs.push(this.images[i].path);
    }
  },
});
</script>

<style>
.product-detailed__carousel.carousel {
  margin-bottom: 82px;
}

.product-carousel-mobile {
  width: 100vw;
  position: relative;
  margin-left: calc(-50vw + 50%);
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--carousel-color-primary);
  color: var(--carousel-color-white);
  font-size: 20px;
  /* border-radius: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 5px;
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(0%, calc(-50% - 15px));
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(0%, calc(-50% - 15px));
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  width: 35px;
  height: 70px;
  text-align: center;
  border-radius: 0;
  font-size: calc(var(--carousel-nav-width) * 2 / 3);
  padding: 0;
  color: var(--carousel-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  outline: none;
  background: rgba(0, 0, 0, 0.5);
}

.carousel__pagination-button {
  margin: 5px;
  width: 20px;
  height: 20px;
  border: 0;
  cursor: pointer;
  background-color: #000;
  border-radius: 50%;
  opacity: 0.25;
}

.carousel__pagination-button--active {
  background-color: #000;
  opacity: 0.75;
}

.carousel__pagination {
  padding: 10px 0 0 0;
}
</style>
