<template>
  <div>
    <vue-final-modal
      v-model="showModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <div v-if="orderStatus" class="message-wrap row">
        <div class="modal-header">
          <pf-button :class="'close-btn'" @click="setState">x</pf-button>
          <div class="logo-cere-oferta">
            <img :src="product.preview_image" alt="Product image" />
          </div>
          <h4 class="fast-order-message">
            {{ orderStatus.message }} Cererea de oferta, pentru {{ product.name }}, a fost
            înregistrată cu succes.
          </h4>
        </div>
      </div>
      <template v-else>
        <div class="modal-header">
          <pf-button :class="'close-btn'" @click="setState">x</pf-button>
        </div>
        <div class="modal__content modal-body">
          <div class="modal-header">
            <div class="logo-cere-oferta">
              <!-- <img src="@/assets/images/product/logo_cere_oferta.png" /> -->
              <img :src="product.preview_image" alt="Product image" />
            </div>
            <h2 class="title-comanda">Cere oferta pentru {{ product.name }}</h2>
            <span class="subtitle-comanda"
              >Completeaza formularul de mai jos, iar un coleg din echipa PEFOC te va
              contacta in maximum 24h lucratoare!</span
            >
          </div>
          <!-- <div v-if="orderStatus" class="message-wrap">
          <h4 class="fast-order-message">{{ orderStatus.message }}</h4>
          <div
            class="col-md-12 col-sm-12 col-xs-12"
            data-v-cee2f47e=""
            data-v-2836fdb5-s=""
          >
            <button type="button" class="btn btn-orange" @click="setState">
              Continua cumparaturile
            </button>
          </div>
        </div> -->
          <div class="comanda-form">
            <Form
              ref="askForOffer"
              @submit="sendOrder"
              class="form-cere-oferta"
              accept-charset="utf-8"
              v-slot="{ errors }"
            >
              <input type="hidden" value="3316" name="item_id" id="prod_id" />
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.last_name"
                    id="contact-name"
                    value=""
                    placeholder="Nume:"
                    class="form-control"
                    :class="{ error: errors['order.property.last_name'] }"
                    :rules="offerNameRules"
                  />
                  <ErrorMessage class="form-error" name="order.property.last_name" />
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.name"
                    id="contact-lastname"
                    value=""
                    placeholder="Prenume:"
                    class="form-control"
                    :class="{ error: errors['order.property.name'] }"
                    :rules="offerNameRules"
                  />
                  <ErrorMessage class="form-error" name="order.property.name" />
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.billing_street"
                    id="street"
                    value=""
                    placeholder="Strada:"
                    class="form-control"
                  />
                </div> -->
                <!-- <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.billing_house"
                    id="address-number"
                    value=""
                    placeholder="Nr, bloc, scara:"
                    class="form-control"
                  />
                </div> -->
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    placeholder="Oras"
                    id="oras"
                    name="order.property.billing_city"
                    class="form-control"
                  >
                  </Field>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    placeholder="Judet:"
                    id="judet"
                    name="order.property.billing_state"
                    class="form-control"
                    value=""
                  >
                  </Field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.phone"
                    id="phone-number"
                    value=""
                    placeholder="Telefon:"
                    class="form-control"
                    :class="{ error: errors['order.property.phone'] }"
                    :rules="offerPhoneRules"
                  />
                  <ErrorMessage class="form-error" name="order.property.phone" />
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                  <Field
                    type="text"
                    name="order.property.email"
                    id="checkout_persoana_billing_email"
                    value=""
                    placeholder="E-mail:"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 form-group">
                  <Field
                    as="textarea"
                    name="order.property.comments"
                    id="message-text"
                    placeholder="Mesaj:"
                    rows="4"
                    class="form-control"
                  />
                </div>
                <!-- <div class="col-md-6 col-sm-6 col-xs-12 form-group"></div> -->
              </div>
              <div class="row form-group policy-row">
                <div class="col-md-6 mb-3">
                  <Field
                    v-if="recaptchaSiteKey?.recaptchaSiteKey"
                    name="recaptcha"
                    :rules="validateRecap"
                  >
                    <vue-recaptcha
                      :sitekey="recaptchaSiteKey.recaptchaSiteKey"
                      language="ro"
                      @verify="handleSuccess"
                      @expired="handleExpired"
                    ></vue-recaptcha>
                  </Field>
                  <ErrorMessage class="form-error" name="recaptcha" />
                </div>

                <div class="col-md-6 mb-3 policy-col">
                  <div class="policy">
                    <Field
                      type="checkbox"
                      name="policy"
                      value="policy"
                      id="checkout_agree2"
                      :rules="validatePolicy"
                    />
                    <label class="checkout_value inline" for="checkout_agree2"
                      >Sunt de acord cu
                      <a
                        href="/politica-de-confidentialitate-a-datelor.html"
                        target="_blank"
                        >politica de confidentialitate a datelor
                        <span class="steluta"> *</span></a
                      ></label
                    >
                  </div>
                  <ErrorMessage class="form-error" name="policy" />
                </div>
              </div>
              <div class="modal__action">
                <pf-button
                  :class="'btn btn-red btn-border-radius comanda-rapida-form'"
                  :id="'trimite-mesaj'"
                  :type="'submit'"
                >
                  Trimite cererea
                </pf-button>
              </div>
            </Form>
          </div>
        </div>
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
import PfButton from "@/components/buttons/PfButton.vue";
import { Form, Field, ErrorMessage, configure } from "vee-validate";
import * as yup from "yup";
import { VueRecaptcha } from "vue-recaptcha";

// Default values
configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default {
  data: () => ({
    offerNameRules: yup.string().required("Campul 'Nume' este obligatoriu"),
    offerPhoneRules: yup
      .string()
      .matches(phoneRegExp, "Numarul de telefon nu este valid")
      .required("Campul 'Telefon' este obligatoriu"),
    recaptcha: false,
    recaptchaToken: "",
  }),

  components: {
    PfButton,
    Form,
    Field,
    ErrorMessage,
    VueRecaptcha,
  },

  computed: {
    product() {
      return this.$store.getters["shop/product"];
    },
    orderStatus() {
      return this.$store.getters["shop/orderMessage"];
    },
    showModal() {
      return this.$store.getters["modals/modalAskForOfferState"];
    },
    recaptchaSiteKey() {
      return this.$store.getters["shop/currency"];
    },
  },

  methods: {
    async sendOrder(values) {
      // console.log(values);

      // Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();

      const payload = {};

      payload.cart = [
        {
          offer_id: this.product.offers[0].id,
          quantity: 1,
          property: {
            offer: true,
          },
        },
      ];

      payload.order = values.order;

      // Execute reCAPTCHA with action "login".
      payload.token = this.recaptchaToken;

      this.$store.dispatch("shop/fastOrder", payload);

      setTimeout(() => {
        console.log("inchidem modal");
        this.showModal = false;
        this.$store.dispatch("shop/resetFastOrder", { root: true });
      }, 10000);
    },
    handleSuccess(value) {
      this.recaptcha = true;
      this.recaptchaToken = value;
      this.$refs.askForOffer.validateField("recaptcha");
      // return value;
    },
    handleExpired() {
      this.recaptcha = false;
      this.recaptchaToken = "";
      this.$refs.askForOffer.validateField("recaptcha");
    },
    setState() {
      this.$store.dispatch("modals/askForOfferState", false);
    },
    validatePolicy(value) {
      if (!value) {
        return "Este necesar sa bifati casuta pentru a finaliza comanda.";
      }
      return true;
    },
    validateRecap() {
      if (!this.recaptcha) {
        return "RECAPTCHA NU ESTE VALIDAT";
      }
      return true;
    },
  },
  mounted() {
    // console.log(this.product);
  },
  created() {
    // this.$root.on("openPopup", () => this.openModal());
  },
};
</script>

<style scoped>
.title-comanda {
  font-family: "Raleway Bold";
  font-size: 24px;
}

.subtitle-comanda {
  font-family: "Raleway Bold";
  font-size: 12px;
  color: #646464;
  max-width: 448px;
  display: inline-block;
  margin-bottom: 20px;
}

.logo-cere-oferta img {
  /* width: 187px; */
  max-width: 187px;
  max-height: 187px;
  margin-bottom: 10px;
}

.message-wrap {
  text-align: center;
  padding: 50px 0px;
}

.fast-order-message {
  color: #000000;
  font-size: 24px;
  text-align: center;
  padding: 20px 0px;
  font-family: "Raleway Bold";
  width: 70%;
}

:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 25px;
  width: 768px;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.policy {
  display: flex;
  align-items: center;
}

.policy-row {
  margin-bottom: 0;
}

.policy-col {
  display: flex;
  flex-wrap: wrap;
}

.form-cere-oferta .policy > label {
  margin-bottom: 0;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .modal-content {
    width: auto;
  }

  .fast-order-message {
    width: auto;
  }
}

@media (max-width: 576px) {
  .subtitle-comanda {
    max-width: 320px;
  }
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

:deep(.modal-content) {
  padding-top: 40px;
}

@media (min-width: 768px) {
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #990000;
    border-radius: 10px;
  }
}

.modal-header {
  border: none;
  padding-bottom: 0;
  text-align: center;
  padding-top: 30px;
  align-items: center;
  flex-direction: column;
}

button.close {
  position: absolute;
  top: 2px;
  right: 0px;
  color: #fff !important;
  z-index: 10;
  opacity: 1;
  width: 65px;
  height: 65px;
  background-color: #c2c2c2;
  font-size: 50px;
  font-weight: lighter;
}

.modal-header .close {
  margin-top: -2px;
}

button.close span {
  font-family: Raleway;
  font-size: 60px;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.form-group input {
  border-radius: 0;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px rgb(204, 204, 204);
}

.form-cere-oferta .form-control {
  border-radius: 25px;
}

.form-group textarea {
  border-radius: 0;
  /* height: 80px; */
  background-color: #ffffff;
  border: solid 1px rgb(204, 204, 204);
}

.form-cere-oferta .form-control {
  border-radius: 0;
  max-height: 80px;
}

.form-group label {
  font-family: "Raleway Bold";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: center;
  /* color: #a94442; */
  /* text-transform: uppercase; */
  margin-bottom: 15px;
}

.form-cere-oferta .form-group label {
  text-align: left;
}

label[for="checkout_agree2"] a {
  color: #9d1300;
}

.steluta {
  font-weight: 700;
  color: #990000;
}

.form-cere-oferta input[type="checkbox"] {
  appearance: initial;
  -moz-appearance: initial;
  -webkit-appearance: initial;
  height: 20px;
  width: 20px;
  border: 1px solid #979797;
  background: #ffffff;
  margin-bottom: -6px;
  margin-right: 4px;
  outline: none;
  position: relative;
  border-radius: 50%;
}

/* placeholder for cere-oferta form */

.form-cere-oferta div.form-group input.form-control::-webkit-input-placeholder,
.form-cere-oferta div.form-group textarea.form-control::-webkit-input-placeholder {
  font-family: "Raleway Medium";
  font-size: 12px;
  color: #6a6666;
  /* text-transform: uppercase; */
}
.form-cere-oferta div.form-group input.form-control:-moz-placeholder,
.form-cere-oferta div.form-group textarea.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Raleway Medium";
  font-size: 1px;
  color: #6a6666;
  /* text-transform: uppercase; */
}
.form-cere-oferta div.form-group input.form-control::-moz-placeholder,
.form-cere-oferta div.form-group textarea.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Raleway Medium";
  font-size: 12px;
  color: #6a6666;
  /* text-transform: uppercase; */
}
.form-cere-oferta div.form-group input.form-control:-ms-input-placeholder,
.form-cere-oferta div.form-group textarea.form-control:-ms-input-placeholder {
  font-family: "Raleway Medium";
  font-size: 12px;
  color: #6a6666;
  /* text-transform: uppercase; */
}

.form-cere-oferta div.form-group input.form-control:focus,
.form-cere-oferta div.form-group textarea.form-control:focus {
  border-color: #9b0000;
  box-shadow: none;
}

.form-cere-oferta div.form-group input.form-control:focus::-webkit-input-placeholder,
.form-cere-oferta div.form-group textarea.form-control:focus::-webkit-input-placeholder {
  color: #9b0000;
}
.form-cere-oferta div.form-group input.form-control:focus:-moz-placeholder,
.form-cere-oferta div.form-group textarea.form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  color: #9b0000;
}
.form-cere-oferta div.form-group input.form-control:focus::-moz-placeholder,
.form-cere-oferta div.form-group textarea.form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: #9b0000;
}
.form-cere-oferta div.form-group input.form-control:focus:-ms-input-placeholder,
.form-cere-oferta div.form-group textarea.form-control:focus:-ms-input-placeholder {
  color: #9b0000;
}

.comanda .modal-content {
  border-radius: 0;
}

.form-cere-oferta .form-control {
  border-radius: 25px;
  padding-left: 20px;
}

/* end placeholder for cere-oferta form */

.form-cere-oferta input[type="checkbox"]:checked:after {
  content: "";
  color: #990000;
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #9d1300;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  top: 50%;
}

.form-group input.error {
  border-color: #a94442;
}

.form-group .error {
  border: 1px solid #a94442;
}
</style>
