<template>
    <div class="title-desc-wrp">
        <h1 class="produs_title" :class="(hochclass, classes)">
            <span itemprop="name">{{ name }}</span>
        </h1>

        <div v-if="code" class="product-val">
            <span>Cod produs:</span> <span itemprop="sku">{{ code }}</span>
        </div>

        <meta v-if="brand" itemprop="brand" :content="brand.name" />

        <div v-if="text" itemprop="description" class="short-description">
            <span v-html="text"> </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BriefInfo",
    props: ["name", "code", "text", "hochclass", "brand", "classes"],
};
</script>

<style lang="scss" scoped>
h1.produs_title {
    &.red-color {
        color: #b60000;
    }

    margin-bottom: 5px;
    font-family: Raleway Bold;
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    font-size: 34px;
    color: #000000;
    line-height: 1;
}

h1.custom-hoch-title {
    margin-bottom: 20px;
}

h1.custom-hoch-title {
    span {
        font-size: 24px;
    }
}

.title-desc-wrp {
    .product-val {
        font-family: Montserrat Medium;
        font-size: 12px;
        color: #888888;
        margin-bottom: 20px;
    }

    .short-description {
        font-family: Montserrat Regular;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: normal;
        color: #222222;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .short-description {
        display: none;
    }
}
</style>
