<template>
  <section class="container p-3 rounded product-page-detailed pb-4">
    <div v-if="product.name" class="row" itemscope itemtype="http://schema.org/Product">
      <div class="col-lg-7 product-image">
        <brief-info
          :name="product.name"
          :code="product.code"
          :text="product.preview_text"
          :brand="product.brand"
        />
        <carousel v-if="windowWidth > 768" :images="product.images" :product="product" />
        <product-carousel-mobile
          v-if="windowWidth < 769"
          :images="product.images"
          :product="product"
        />
      </div>
      <div class="col-lg-5 product-info">
        <template
          v-if="
            product.offers.length &&
            product.offers[0].price_value > 0 &&
            product.active &&
            product.sold_out !== 1
          "
        >
          <product-price :offer="product.offers[0]" />
          <ProductStock v-if="!product.hide_stock" :offer="product.offers[0]" :soldout="product.sold_out" />
          <shipping-date v-if="product.available_from" :date="product.available_from" />
          <action-buttons :offer="product.offers[0]" />
        </template>
        <template v-else-if="!product.active">
          <inactive-product-message :categ="product.fist_categ" />
        </template>
        <template v-else-if="product.sold_out === 1">
          <div class="alert alert-danger col-md-12">
            <strong>Stoc epuizat</strong>
            <meta itemprop="price" content="<?php echo $produs->pret; ?>" />
            <meta itemprop="priceCurrency" content="LEI" />
          </div>
        </template>
        <template v-else-if="product.offers[0].price_value === 0">
          <ProductStock v-if="!product.hide_stock" :offer="product.offers[0]" :soldout="product.sold_out" />
          <hr style="margin-bottom: 20px;" />
          <ask-for-offer :technical="product.technical_sheet" />
          <ask-for-offer-modal ref="askForOfferModal" />
          <hr />
        </template>

        <specifications :properties="properties" />
        <secondory-description :description="product.bottom_content" />

        <pf-button
          v-if="product.technical_sheet"
          :style="{ marginTop: '10px' }"
          :class="'btn btn-default btn-large tech-btn btn-black '"
          :href="product.technical_sheet"
          target="_blank"
        >
          Detalii Tehnice
        </pf-button>

        <form class="mb-0">
          <input type="hidden" name="offer_id" value="{{ obOffer.id }}" />
        </form>

        <div class="description-more">
          <span @click="goto('.product-content')">
            <img width="37" height="37" src="@/assets/images/product/round-plus.svg" />
            Mai multe detalii produs
          </span>
        </div>
        <hr />
      </div>
      <div class="col-lg-12 product-bw">
        <bought-with-products v-if="boughtWithProducts.length > 0" :products="boughtWithProducts" />
        <SkeletonBoughtWithProductsCarousel v-else-if="hasBoughtWithProducts" />
      </div>
      <div class="col-lg-12 product-rc">
        <recommended-categories v-if="recommendedCategories.length > 0" :categories="recommendedCategories" />
        <SkeletonRecommendedCategoriesCarousel v-else-if="hasRecommendedCategories" />
      </div>
      <div
        class="wysiwyg mt-3 product-content col-lg-12 product-description"
      >
        <div class="options-title" @click="openDescription">
          Detalii privind produsul
          <div class="p-0 m-0 float-right open-description">
            <img v-show="!isDescriptionOpen" width="46" height="46" src="@/assets/images/product/round-plus.svg" />
            <img v-show="isDescriptionOpen" width="46" height="46" src="@/assets/images/product/round-minus.svg" />
          </div>
        </div>
        <more-info v-show="isDescriptionOpen" :description="product.description" />
      </div>
      <div class="col-lg-12 product-rp" >
        <related-products v-if="relatedProducts.length > 0" :products="relatedProducts" />
        <SkeletonRelatedProductsCarousel v-else-if="hasRelatedProducts" />
      </div>
      <div class="col-lg-12 product-info-points">
        <info-points :slug="product.slug" :id="product.id" />
      </div>
    </div>
  </section>
</template>

<script>
import BriefInfo from "@/components/product_page/BriefInfo.vue";
import Carousel from "@/components/product_page/Carousel.vue";
import ProductCarouselMobile from "@/components/product_page/ProductCarouselMobile.vue";
import ProductPrice from "@/components/product_page/ProductPrice.vue";
import ActionButtons from "@/components/product_page/ActionButtons.vue";
import Specifications from "@/components/product_page/Specifications.vue";
import MoreInfo from "@/components/product_page/MoreInfo.vue";
// import ProductOptions from "@/components/product_page/ProductOptions.vue";
import InfoPoints from "@/components/product_page/InfoPoints.vue";
import AskForOffer from "@/components/product_page/AskForOffer.vue";
import SecondoryDescription from "@/components/product_page/SecondoryDescription.vue";
import PfButton from "@/components/buttons/PfButton.vue";
import InactiveProductMessage from "@/components/product_page/InactiveProductMessage.vue";
import ShippingDate from "@/components/product_page/ShippingDate.vue";
import AskForOfferModal from "@/components/product_page/AskForOfferModal.vue";
import ProductStock from "@/components/product_page/ProductStock.vue";
import RelatedProducts from "@/components/product_page/RelatedProducts.vue";
import RecommendedCategories from "@/components/product_page/RecommendedCategories.vue";
import BoughtWithProducts from "@/components/product_page/BoughtWithProducts.vue";
import SkeletonBoughtWithProductsCarousel from "@/components/blocks/skeleton/shop/SkeletonBoughtWithProductsCarousel.vue";
import SkeletonRecommendedCategoriesCarousel from "@/components/blocks/skeleton/shop/SkeletonRecommendedCategoriesCarousel.vue";
import SkeletonRelatedProductsCarousel from "@/components/blocks/skeleton/shop/SkeletonRelatedProductsCarousel.vue";

export default {
  name: "ProductContainer",
  data() {
    return {
      windowWidth: window.innerWidth,
      relatedProducts: [],
      hasRelatedProducts: true,
      recommendedCategories: [],
      hasRecommendedCategories: true,
      boughtWithProducts: [],
      hasBoughtWithProducts: true,
      isDescriptionOpen: true,
    };
  },
  components: {
    "brief-info": BriefInfo,
    Carousel,
    "product-price": ProductPrice,
    ActionButtons,
    Specifications,
    MoreInfo,
    // ProductOptions,
    InfoPoints,
    AskForOffer,
    SecondoryDescription,
    PfButton,
    InactiveProductMessage,
    ProductCarouselMobile,
    ShippingDate,
    AskForOfferModal,
    ProductStock,
    RelatedProducts,
    RecommendedCategories,
    BoughtWithProducts,
    SkeletonBoughtWithProductsCarousel,
    SkeletonRecommendedCategoriesCarousel,
    SkeletonRelatedProductsCarousel,
  },
  methods: {
    goto(id) {
      let element = document.querySelector(id);
      // Calculate position to scroll to
      const y = element.getBoundingClientRect().top + window.pageYOffset - 90;
      window.scrollTo({top: y, behavior: 'smooth'});
      this.isDescriptionOpen = true;
    },
    openDescription() {
      this.isDescriptionOpen = this.isDescriptionOpen == true ? false : true;
    },
  },
  computed: {
    product() {
      // console.log(this.$store.getters["shop/product"].active === 0);
      return this.$store.getters["shop/product"];
    },

    properties() {
      let new_array = this.product.property.filter((elem) => {
        return elem.code !== "layout";
      });

      return new_array;
    },
  },

  created() {
    this.$store
      .dispatch("shop/getRelatedProducts", this.product.slug)
      .then((response) => {
        this.relatedProducts = response.data;
        if(this.relatedProducts.length == 0) {
          this.hasRelatedProducts = false;
        }
      });

    this.$store
      .dispatch("shop/getRecommendedCategories", this.product.slug)
      .then((response) => {
        this.recommendedCategories = response.data;
        if(this.recommendedCategories.length == 0) {
          this.hasRecommendedCategories = false;
        }
      });

    this.$store
      .dispatch("shop/getBoughtWithProducts", this.product.slug)
      .then((response) => {
        this.boughtWithProducts = response.data;
        if(this.boughtWithProducts.length == 0) {
          this.hasBoughtWithProducts = false;
        }
      });
  }
};
</script>

<style>
@import "../../assets/css/product.css";
@import "../../assets/css/common.css";
@import "../../assets/css/list_products.css";
</style>
