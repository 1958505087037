<template>
  <div
    v-if="offer"
    itemprop="offers"
    itemscope
    itemtype="http://schema.org/Offer"
    class="product-card__price-bar mb-2"
  >
    <meta itemprop="url" :content="publicPath + $route.fullPath.substring(1)" />

    <!-- {% if obOffer.old_price_value > 0 %} -->
    <div v-if="isPromo(offer)" class="produs-pret-vechi">
      PRET vechi: <span>{{ offer.old_price.split(".")[0] }}</span> LEI -
      {{ priceInEuro(offer.old_price_value) }} €
    </div>
    <!-- {% endif %} -->
    <div class="produs-pret" :class="{ 'promotion-color': isPromo(offer) }">
      <span itemprop="price" :content="offer.price">{{ offer.price.split(".")[0] }}</span>
      <span itemprop="priceCurrency" content="RON"> lei</span>
      <span class="f_size_small"
        >( {{ priceInEuro(offer.price_value) }} € ) pretul include TVA
      </span>
    </div>

    <link
      v-if="offer.quantity"
      itemprop="availability"
      href="http://schema.org/InStock"
    />
    <link v-else itemprop="availability" href="http://schema.org/OutOfStock" />
  </div>
</template>

<script>
import { shopMixin } from "@/mixins/shopMixin";

export default {
  name: "ProductPrice",
  props: ["offer"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {},
  methods: {
    isPromo(offer) {
      if (offer.old_price_value > 0) {
        return true;
      }
      return false;
    },
  },
  mixins: [shopMixin],
};
</script>

<style scoped>
.addtocartfixed .produs-pret-vechi {
  font-size: 10px;
}

.addtocartfixed .produs-pret {
  font-size: 16.8px;
  margin-bottom: 0;
}

.addtocartfixed .produs-pret .f_size_small {
  font-size: 10px;
}

.addtocartfixed .product-card__price-bar {
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .f_size_small {
    font-family: "Montserrat";
    font-size: 14px;
  }

  .produs-pret {
    font-size: 28px;
  }
}
</style>
