<template>
  <div
    id="carouselExampleIndicators"
    class="carousel product-detailed__carousel slide"
    :class="{'position-sticky': posSticky, 'position-relative': posRelative }"
    data-ride="carousel"
  >
    <div class="thumb-gallery" v-if="images">
      <ol class="carousel-indicators">
        <li
          v-for="(image, idx) in images"
          :key="idx"
          data-target="#carouselExampleIndicators"
          :data-slide-to="idx"
          class="carousel-part"
          :class="idx === index ? 'active' : ''"
          @click="() => show(idx)"
          @mouseover="hover(idx)"
        >
          <img
            :itemprop="schema(idx)"
            class="d-none d-md-block"
            :src="image.thumb"
            alt="Image"
            title="Image"
            loading="lazy"
          />
        </li>
      </ol>
    </div>
    <div
      v-if="images.length > 0"
      class="carousel-inner mb-3 mb-lg-0 product-detailed__carousel-inner"
    >
      <div
        v-for="(image, idx) in images"
        :key="idx"
        class="carousel-item bg-white product-detailed__carousel-item h-100"
        :class="idx === index ? 'active' : ''"
        @click="() => show(idx)"
      >
        <img
          class="d-block w-100 h-100 product-detailed__carousel-item-img"
          :src="image.path !== '' ? image.path : '@/assets/images/product/default.jpg'"
          alt=""
          title=""
          loading="lazy"
        />
      </div>
      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
    <div v-else class="carousel-inner mb-3 mb-lg-0 product-detailed__carousel-inner">
      <div
        key="default"
        class="carousel-item bg-white product-detailed__carousel-item h-100 active"
        @click="() => show('default')"
      >
        <img
          class="d-block w-100 h-100 product-detailed__carousel-item-img"
          src="@/assets/images/product/default.jpg"
          alt="Imagine inexistenta"
          title="Imagine inexistenta"
          loading="lazy"
        />
      </div>
    </div>
    <carousel-prev @click="prevPhoto" v-if="images.length > 0" />
    <!-- <carousel-prev v-if="images.length > 0" /> -->
    <carousel-next @click="nextPhoto" v-if="images.length > 0" />
    <!-- <carousel-next v-if="images.length > 0" /> -->
    <product-ribons :ribons="productLabels" :product="product" />
  </div>
</template>

<script>
import ProductRibons from "@/components/product_page/ProductRibons.vue";
import CarouselPrev from "@/components/product_page/CarouselPrev.vue";
import CarouselNext from "@/components/product_page/CarouselNext.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "Carousel",
  components: {
    "product-ribons": ProductRibons,
    "carousel-prev": CarouselPrev,
    "carousel-next": CarouselNext,
    VueEasyLightbox,
  },
  props: ["images", "product", "type"],

  data() {
    return {
      imgs: [],
      visible: false,
      index: 0, // default: 0
      ct: 0,
      ctprevnext: 0,
      threshold: 0,
      thumbposition: 1,
      startPosition: 1,
      endPosition: 1,
      thumbCarouselLength: 0,
    };
  },

  computed: {
    widthThumb() {
      // oare cum as putea prelua doar cand e gata dom-ul
      // return document.querySelector(".carousel-part img").offsetWidth;
      return 82;
    },

    isDesktopDevice() {
      return this.$mq === "lg" ? true : false;
    },

    posSticky() {
      if(!this.visible && !this.product?.layout_property.length && this.type != 'custom') {
        return true;
      } else {
        return false;
      }
    },

    posRelative() {
      if( this.visible || this.product?.layout_property.length || this.type == 'custom') {
        return true;
      } else {
        return false;
      }
    },
    
    productLabels() {
      let labels = [];
      let ecoDesign = false;
      if(this.product?.property?.length > 0) {
        this.product?.property.forEach((elem) => {
          if(elem.value == "Eco Design") {
            ecoDesign = true;
          }
        });
      }

      if(this.product?.category_labels?.length > 0) {
        this.product.category_labels.forEach((element) => {
          if(element.code == "pro") {
            labels.push(element);
          }
        });
      }

      // Push all product labels to the array
      if(this.product?.labels?.length > 0) {
        this.product.labels.forEach((element) => {
          if(ecoDesign && element.code == "transport-gratuit") return;
          if(!ecoDesign && element.code == "eco-design") return;
          labels.push(element);
        });
      }
      
      return labels;
    },
  },

  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    hover(index) {
      // console.log("current index", this.index);
      // console.log("new index", index);
      let carouselItems = document.getElementsByClassName("carousel-item");
      Array.from(carouselItems).forEach((carouselItem, carouselIndex) => {
        // console.log(carouselIndex);
        if (carouselIndex !== index) {
          carouselItem.classList.remove("active");
        } else {
          this.index = index;
        }
      });
      // console.log(carouselItems);
      // this.index = index;
    },
    handleHide() {
      this.visible = false;
    },
    nextPhoto() {
      // this.thumbposition++;

      // if (this.thumbposition > this.thumbCarouselLength) {
      //   this.thumbposition = 1;
      //   this.startPosition = 1;
      //   this.endPosition = 4;
      //   this.moveGallery(this.widthThumb * this.threshold);
      // }
      // if (this.ctprevnext > 0 && this.isDesktopDevice) {
      //   // console.log("index", this.index);

      //   this.ctprevnext--;
      // }

      // if (
      //   this.thumbposition > this.endPosition &&
      //   this.thumbposition <= this.thumbCarouselLength
      // ) {
      //   this.startPosition++;
      //   this.endPosition++;
      //   this.moveGallery(-this.widthThumb);
      // }
      // this.ctprevnext++;
      // console.log(this.ctprevnext);
      if (this.ctprevnext > 0 && this.isDesktopDevice) {
        this.ctprevnext--;
        this.moveGallery(-this.widthThumb);
      }
    },
    prevPhoto() {
      // this.thumbposition--;

      // if (this.thumbposition < 1) {
      //   this.thumbposition = this.thumbCarouselLength;
      //   this.startPosition = this.thumbCarouselLength - this.threshold;
      //   this.endPosition = this.thumbCarouselLength;
      //   this.moveGallery(-this.widthThumb * this.threshold);
      // }

      // if (this.thumbposition < this.startPosition && this.thumbposition >= 1) {
      //   this.startPosition--;
      //   this.endPosition--;
      //   this.moveGallery(this.widthThumb);
      // }
      // this.ctprevnext--;
      if (this.ctprevnext < this.threshold && this.isDesktopDevice) {
        this.ctprevnext++;
        this.moveGallery(this.widthThumb);
      }
    },
    moveGallery(offset) {
      var ol = document.querySelector(".carousel-indicators");
      this.ct += offset;
      ol.style.transform = `translate3d(${this.ct}px,0px,0px)`;
      ol.style.transition = "all 0.25s ease 0s";
    },

    moveThumbGallery() {
      this.$nextTick(function () {
        // Code that will run only after the
        // entire view has been rendered

        for (var i = 0; i < this.images.length; i++) {
          this.imgs.push(this.images[i].path);
        }

        let wrpthumb = document.querySelector(".thumb-gallery");
        let nrFit = Math.floor(wrpthumb.offsetWidth / this.widthThumb);
        // console.log("nr Fit", nrFit);

        this.ctprevnext = this.images.length - nrFit;
        // console.log("contor prev next", this.ctprevnext); // cat elemente mai raman
        this.threshold = this.ctprevnext;
        // console.log(this.threshold);

        this.endPosition = nrFit;
        this.thumbCarouselLength = this.images.length;
        // console.log("start position", this.startPosition);
        // console.log("end position", this.endPosition);
      });
    },

    schema(index) {
      return index === 0 ? "image" : "";
    },

    // imageDefault(path) {
    //   try {
    //     return require(path);
    //   } catch (e) {
    //     return "@/assets/images/product/default.jpg";
    //   }
    // },
  },

  mounted() {
    // console.log("mounted");
    this.moveThumbGallery();
    // console.log(this.images);
    console.log(this.product);
  },

  updated() {
    // this.moveThumbGallery();
  },
};
</script>

<style scoped>
.product-detailed__carousel.carousel {
  margin-left: 82px;
  /* position: sticky; */
  top: 54px;
  width: calc(100% - 82px);
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .carousel-control-next,
  .carousel-control-prev {
    background: #d3d3d366;
    opacity: 1;
    top: unset;
    right: unset;
    bottom: unset;
    left: -82px;
  }

  .carousel-control-prev {
    top: 0;
  }

  .carousel-control-next {
    bottom: 0;
  }

  .thumb-gallery {
    position: absolute;
    bottom: 262px;
    height: 82px;
    width: calc(100% - 162px);
    left: -262px;
    overflow: hidden;
    transform: rotate(90deg);
  }

  .thumb-gallery .carousel-indicators {
    margin-left: 0;
    margin-right: 0;
    position: initial;
  }

  .carousel-indicators {
    bottom: -82px;
    height: 82px;
    margin-bottom: 0;
    justify-content: left;
    margin-left: 82px;
    margin-right: 82px;
  }

  .carousel-indicators li > img {
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    transform: rotate(-90deg);
  }
}

@media (min-width: 991px) { 
  .thumb-gallery {
    bottom: 184px;
    left: -184px;
  }
}

@media (min-width: 1200px) { 
  .thumb-gallery {
    bottom: 236px;
    left: -236px;
  }
}
</style>
