<template>
  <div>
    <div class="item-description">
      <label>{{ property.name }}: </label>
      <div class="value">
        <span v-html="property.value"></span> <span v-html="property?.measure"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Specifications",
  props: ["property"],

  mounted() {
    // console.log(this.property);
  },
};
</script>

<style scoped>
@media print {
  .item-description > label {
    font-weight: bold;
  }
}

.product-page-detailed .item-description label {
  margin-bottom: 0px;
}
.product-page-detailed .item-description .value {
  margin-bottom: 0.5rem;
}

@media (max-width: 576px) {
  .product-page-detailed .item-description label,
  .product-page-detailed .item-description .value {
    font-size: 12px;
  }
}
</style>
